import { changePassword } from './../utils/users.utils';
import { ConfigState } from './appConfig';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { put, call, all, takeLatest, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import AOMSessionToken from '../sdk/com/apiomat/frontend/AOMSessionToken';
import { notificationActions } from './notification';
import { AppState } from './index';
import Datastore from '../sdk/com/apiomat/frontend/Datastore';
import LPUser from '../sdk/com/apiomat/frontend/mylearningplatform/LPUser';
import i18n from 'i18next';
import { fallbackLanguage } from '../utils/i18n';
import { createTransform } from 'redux-persist';
import AbstractClientDataModel from '../sdk/com/apiomat/frontend/AbstractClientDataModel';
import PasswordResetRequest from '../sdk/com/apiomat/frontend/mylearningplatform/PasswordResetRequest';
import AOMStatus from '../sdk/com/apiomat/frontend/Status';
import { ApiRequestState } from '../models/api-request-state';
import { LoginState } from '../enums/LoginState';

/** STATE */
export interface AuthState {
  user: LPUser | null;
  token: AOMSessionToken | null;
  isAuthenticated: boolean;
  isAdmin: boolean;
  isGlobalAdmin: boolean;
  isDeletionAdmin: boolean;
  isManager: boolean;
  redirectAfterAuth: string;
  loading: ApiRequestState;
  loadingByToken: ApiRequestState;
  loadingRegistration: ApiRequestState;
  isAdminAreaNavigation: boolean;
  missingInitialization: Array<LoginState.PASSWORD | LoginState.LANGUAGE>;
}

const initialState: AuthState = {
  user: null,
  token: null,
  isAuthenticated: false,
  isAdmin: false,
  isGlobalAdmin: false,
  isDeletionAdmin: false,
  isManager: false,
  redirectAfterAuth: null,
  loading: 'idle',
  loadingByToken: 'idle',
  loadingRegistration: 'idle',
  isAdminAreaNavigation: false,
  missingInitialization: []
};

/** TYPES */
export interface UserCredentials {
  userName: string;
  password: string;
}

export interface UpdatePassword {
  user: LPUser;
  newPassword: string;
}

type ExtendedUser = LPUser & { staffMembers?: LPUser[] };

export interface LoginObject {
  user: ExtendedUser;
  token: AOMSessionToken;
}

const userCompletelyInitialized = (user: LPUser) => {
  return Boolean(user.defaultLanguage) && !Boolean(user.isInitialPW);
};

const checkMissingInitialization = (user: LPUser) => {
  const isLangMissing = !user.defaultLanguage;
  const isPasswordMissing = !!user.isInitialPW;
  const missingInitialization = [];
  if (isLangMissing) {
    missingInitialization.push(LoginState.LANGUAGE);
  }
  if (isPasswordMissing) {
    missingInitialization.push(LoginState.PASSWORD);
  }

  return missingInitialization;
};

/** SLICE */
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginWithCredentials: (state, _action: PayloadAction<UserCredentials>) => {
      state.loading = 'pending';
    },
    loginWithToken: state => {
      state.loadingByToken = 'pending';
    },
    loginSuccess: (state, action: PayloadAction<LoginObject>) => {
      const { user, token } = action.payload;
      state.loading = 'succeeded';
      state.loadingByToken = 'succeeded';
      state.user = user;
      state.token = token;
      state.isAuthenticated = true;
      state.isAdmin = Boolean(user.isAdmin);
      state.isGlobalAdmin = user.isAdmin === 2 || user.isAdmin === 3;
      state.isDeletionAdmin = user.isAdmin === 3;
      state.isManager = user.staffMembers?.length > 1;
      state.missingInitialization = [];
    },
    preliminaryLoginSuccess: (state, action: PayloadAction<LPUser>) => {
      state.user = action.payload;
      state.loading = 'succeeded';
      state.loadingByToken = 'succeeded';
      state.missingInitialization = checkMissingInitialization(action.payload);
    },
    loginFailure: state => {
      state.loading = 'failed';
      state.isAuthenticated = false;
      state.isAdmin = false;
      state.isGlobalAdmin = false;
      state.isDeletionAdmin = false;
      state.isManager = false;
      state.user = null;
      state.token = null;
    },
    reloadUser: () => {
    },
    updateUser: (state, action: PayloadAction<LPUser>) => {
      state.loading = 'pending';
      state.user = action.payload;
    },
    updateUserSuccess: (state, action: PayloadAction<LPUser>) => {
      state.loading = 'succeeded';
      state.missingInitialization = checkMissingInitialization(action.payload);
    },
    updateUserFailure: state => {
      state.loading = 'failed';
    },
    updateUserPassword: (state, _action: PayloadAction<UpdatePassword>) => {
      state.loading = 'pending';
    },
    updateUserPasswordSuccess: state => {
      state.loading = 'succeeded';
      state.missingInitialization = state.missingInitialization.filter(item => item !== LoginState.PASSWORD);
    },
    updateUserPasswordFailure: state => {
      state.loading = 'failed';
    },
    logout: state => {
      state.loading = 'idle';
      state.user = null;
      state.token = null;
      state.isAuthenticated = false;
      state.redirectAfterAuth = null;
    },
    setIsAdminAreaNavigation: (state, action: PayloadAction<boolean>) => {
      state.isAdminAreaNavigation = action.payload;
    },
    setRedirectAfterAuth: (state, action: PayloadAction<string>) => {
      state.redirectAfterAuth = action.payload;
    },
    resetPassword: (state, action: PayloadAction<string>) => {
      state.loading = 'pending';
    },
    resetPasswordSuccess: state => {
      state.loading = 'succeeded';
    },
    resetPasswordFailure: state => {
      state.loading = 'failed';
    }
  }
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;

/** SAGAS */
function* onLoginWithCredentials(action: PayloadAction<UserCredentials>) {
  const { userName, password } = action.payload;
  const configState: ConfigState = yield select((state: AppState) => state.appConfig);
  const maxLoginAttempts = configState?.appConfig?.maximumLoginAttempts;

  try {
    let user = new LPUser() as ExtendedUser;
    user.userName = userName?.toLowerCase().trim();
    user.password = password.trim();

    Datastore.configureAsUser(user);
    yield call(() => user.loadMe());

    if (!userCompletelyInitialized(user)) {
      user.password = password.trim();
      return yield put(authActions.preliminaryLoginSuccess(user));
    }

    yield call(async () => (user.staffMembers = await LPUser.getLPUsers('limit 2')));
    const token = yield call(() => user.requestSessionToken());
    yield put(authActions.loginSuccess({ user, token }));

    if (user.defaultLanguage) {
      i18n.changeLanguage(user.defaultLanguage);
    }
  } catch (error) {
    if (error.statusCode === AOMStatus.ACCOUNT_TEMPORARY_BLOCKED) {
      yield put(notificationActions.showError(i18n.t('errors.blocked-user')));
      yield put(authActions.loginFailure());
    } else {
      if (isNaN(maxLoginAttempts) || typeof maxLoginAttempts !== 'number') {
        yield put(notificationActions.showError(i18n.t('errors.invalid-credentials-wo-maxLoginAttempts')));
      } else {
        yield put(notificationActions.showError(i18n.t('errors.invalid-credentials', { maxLoginAttempts: maxLoginAttempts })));
      }
      yield put(authActions.loginFailure());
    }
  }
}

function* onLoginWithToken() {
  let user = new LPUser() as ExtendedUser;
  let token = yield select((state: AppState) => state.auth.token);
  let loginObject: LoginObject;

  try {
    if (token.expirationDate > Date.now()) {
      user.sessionToken = token.sessionToken;

      Datastore.configureWithSessionToken(token.sessionToken);
      try {
        yield call(() => user.loadMe());
        yield call(async () => (user.staffMembers = await LPUser.getLPUsers('limit 2')));
        loginObject = { user, token };
      } catch {
        /** failed to login with existing session token, trying refresh token */
        loginObject = yield call(() => refreshToken(token));
      }
    } else {
      /** existing session token expired, trying refresh token */
      loginObject = yield call(() => refreshToken(token));
    }

    yield put(authActions.loginSuccess(loginObject));
    i18n.changeLanguage(user.defaultLanguage || fallbackLanguage);
  } catch (error) {
    yield put(push('/'));
    yield put(authActions.loginFailure());
  }
}

const refreshToken = async (token: AOMSessionToken): Promise<LoginObject> => {
  const user = new LPUser();
  user.sessionToken = token.sessionToken;
  Datastore.configureWithSessionToken(token.sessionToken);

  const newToken = await user.requestSessionToken(true, token.refreshToken);
  user.sessionToken = newToken.sessionToken;
  await user.loadMe();

  return { user, token: newToken };
};

function* onReloadUser() {
  const user: ExtendedUser = yield select((state: AppState) => state.auth.user);

  try {
    yield call(() => user.load());
    yield call(async () => (user.staffMembers = await LPUser.getLPUsers('limit 2')));
    yield call(() => user.save(false));
  } catch (error) {
    yield put(notificationActions.showError(error));
  }
}

function* onUpdateUser(action: PayloadAction<LPUser>) {
  const user = action.payload;
  try {
    yield call(() => user.save(false));
    yield put(authActions.updateUserSuccess(user));
    yield put(authActions.loginWithCredentials(user));
  } catch (error) {
    yield put(authActions.updateUserFailure());
    yield put(notificationActions.showError(error));
  }
}

function* onUpdateUserPassword(action: PayloadAction<UpdatePassword>) {
  const { user, newPassword } = action.payload;
  const initalPassword = user.password;

  try {
    yield call(() => changePassword(user, newPassword));
    yield put(authActions.updateUserPasswordSuccess());
    yield put(authActions.loginWithCredentials({ userName: user.userName, password: newPassword }));
  } catch (error) {
    yield put(notificationActions.showError(i18n.t('errors.save-password-failed')));
    user.password = initalPassword;
    yield put(authActions.updateUserPasswordFailure());
    yield put(notificationActions.showError(error));
  }
}

function* onResetPassword(action: PayloadAction<string>) {
  const requestingUserName = action.payload;
  if (!requestingUserName) {
    yield put(notificationActions.showError(i18n.t('forgot-password.enter-username')));
  } else {
    try {
      const resetRequest = new PasswordResetRequest();
      resetRequest.userName = requestingUserName.trim();

      yield call(() => resetRequest.save());
      yield put(notificationActions.showSuccessMessage(i18n.t('forgot-password.mail-sent')));
      yield put(authActions.resetPasswordSuccess());
      yield put(push('/login'));
    } catch (error) {
      if (error.message === 'No userMail found') {
        yield put(notificationActions.showError(i18n.t('forgot-password.no-mail')));
        yield put(authActions.resetPasswordFailure());
      } else if (error.message === 'No users with this username found') {
        yield put(notificationActions.showError(i18n.t('forgot-password.no-user')));
        yield put(authActions.resetPasswordFailure());
      }
    }
  }
}

function* onLogout() {
  Datastore.configureAsGuest();
  yield put(push('/login'));
}

export function* authSaga() {
  yield all([
    takeLatest(authActions.loginWithCredentials, onLoginWithCredentials),
    takeLatest(authActions.loginWithToken, onLoginWithToken),
    takeLatest(authActions.reloadUser, onReloadUser),
    takeLatest(authActions.updateUser, onUpdateUser),
    takeLatest(authActions.updateUserPassword, onUpdateUserPassword),
    takeLatest(authActions.logout, onLogout),
    takeLatest(authActions.resetPassword, onResetPassword)
  ]);
}

/** TRANSFORMS */
export const toJson = (instance: AbstractClientDataModel): any => {
  return instance.toJson();
};

export const authTransform = createTransform(
  (state: AuthState) => {
    return {
      ...state,
      user: state.user && toJson(state.user)
    };
  },
  json => {
    return {
      user: null,
      token: json.token,
      isAuthenticated: false,
      isAdmin: false,
      isGlobalAdmin: false,
      isManager: false,
      loading: 'idle',
      loadingByToken: 'idle',
      loadingRegistration: 'idle',
      isAdminAreaNavigation: false
    };
  },
  { whitelist: [ 'auth' ] }
);
